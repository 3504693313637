<template>
  <div style="height: 100%;">
    <va-modal
      v-model="showEditModal"
      :title="`${editingComponent.displayName} Component`"
      ok-text="Save Component"
      :cancel-text=" $t('modal.cancel') "
      @ok="saveComponent"
      class="flex xs12"
    >
      <slot>
        <component-edit

          ref="componentModal"
          @edit-done="saveToView"
          :component-template="editingComponent"
        />
      </slot>
    </va-modal>
    <va-modal
      v-model="showQuestionSelection"
      :title="'Select question Type'"
      :hide-default-actions="true"
      class="flex xs12"
    >
      <slot>
        <component-list
          style="padding-top: 3px;"
          @add-item="addItem"
        />
      </slot>
    </va-modal>
    <va-modal
      v-model="selectEvalModal"
      title="Eval Manager"
      :hide-default-actions="true"
      class="flex xs12"
    >
      <slot>
        <manager />
      </slot>
    </va-modal>
    <div
      v-if="!this.activeDocumentId"
      class="centerAll"
    >
      <va-card>
        Select evaluation <va-button
          icon="fa fa-folder"
          @click="selectEvalModal = true"
        />
      </va-card>
    </div>
    <div
      v-else
      class="row"
    >
      <div
        style="position: relative;"
        class="flex xs12"
      >
        <va-card
          @click="clearSelection"
          :title="title"
        >
          <template slot="actions">
            <va-button
              icon="fa fa-eye"
              title="Check Preview"
              @click="SaveAndGoToPreview"
            />
            <va-button
              :icon="lockedEval ? 'fa fa-lock': 'fa fa-unlock'"
              title="Prevent the eval from being deleted"
              @click="toggleLock"
            />
            <va-button
              icon="fa fa-folder"
              title="Open Eval"
              @click="selectEvalModal = true"
            />
            <va-button
              :disabled="saving || lockedEval"
              icon="fa fa-save"
              title="Save"
              @click="saveEval"
            />
          </template>

          <div>
            <div
              @click.self="removeSelection"
              style="position: absolute; height: 100%; width: 100%; top: 3.5em; left: 0; z-index: 5; border-top: 1px solid gray;"
            >
            &nbsp;<!-- mask for clicking outside an item -->
            </div>
            <demo-view
              style="position: relative; z-index: 10;"
              @add-question="addQuestion"
              @add-element="addElement"
              @remove-section="removeSection"
            />
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>
<script>
import EvalEditorCatcher from '../../class/EvalEditorCatcher';
import componentList from '../eval/componentList.vue';
import evalComponents from '../../data/evalComponents';
import componentEdit from '../eval/componentEdit.vue';
import demoView from '../eval/demoView.vue';
import manager from '../eval/manager.vue';
import firebase from 'firebase';
import {mapGetters} from 'vuex';
export default {
  components: {
    componentList,
    componentEdit,
    demoView,
    manager,

  },

  methods: {
    clearSelection() {
      console.log('clearing selection');
      // this.$store.dispatch('clearEvalItem');
    },
    toggleLock() {
      this.$store.dispatch('toggleLock');
    },
    validateEval(evaluation) {
      let EvalCatcher = new EvalEditorCatcher(evaluation);
      try{
        EvalCatcher.validate_hidden_fields();
        EvalCatcher.validate_ticket_fields();
      } catch (error) {
        throw error;
      }
    },
    /**
     * @description This will save the preview (Not in the same place as the production eval) and then go to the preview page
     */
    SaveAndGoToPreview(){
      const id = this.activeDocumentId;
      this.saving = true;
      try {
        this.validateEval(this.$store.state.evaluation.evaluation);
      } catch (error) {
        this.$swal.fire({
          title: 'Fill the requirements',
          text: error.message,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        this.saving = false;
        return;
      } 
      console.log('saving preview');
     //swal loading
      this.$swal.fire({
        title: 'Loading Your Preview',
        text: 'Please wait, this may take a few time, do not close this window.  Any changes you made will not affect the production eval, until you save it.',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        showLoaderOnConfirm: true,
      });
      firebase.firestore().collection('evalTemplates').doc(id).update({
        evaluationLastPreview: this.$store.state.evaluation.evaluation,
      }).then(()=>{
        this.saving = false;
        window.open(`/editor/eval/preview/${id}`, '_blank');
        //swal close
        this.$swal.close();
      });
    },
    saveEval() {
      const id = this.activeDocumentId;
      this.saving = true;
      try {
        this.validateEval(this.$store.state.evaluation.evaluation);
      } catch (error) {
        this.$swal.fire({
          title: 'Fill the requirements',
          text: error.message,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        this.saving = false;
        return;
      } 
      firebase.firestore().collection('evalTemplates').doc(id).update({
        evaluation: this.$store.state.evaluation.evaluation,
      }).then(()=>{
        this.saving = false;
      });
    },
    getEval(id) {
      this.selectEvalModal = false;
      firebase.firestore().collection('evalTemplates').doc(id).get().then((snapshot)=>{
        this.activeDocumentId = snapshot.id;
        this.$store.dispatch('setEvaluation', snapshot.data().evaluation);
        this.$store.dispatch('setTitle', snapshot.data().title);
        this.$store.dispatch('setId', this.activeDocumentId);
        this.$store.dispatch('setLockState', snapshot.data().locked ? true : false);
      });
    },
    async getEvaluationCategories() {
      let evalData = await firebase.firestore().collection('evalCategories').where("enabled", '==', true).get().then((snapshot)=>{
        //save data to setCategories from store
        return snapshot.docs.map((doc)=>{
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      });
      this.$store.dispatch('setCategories', evalData);
    },
    addQuestion(data) {
      this.sectionSaveId = data.section.sectionId;
      this.addingQuestion = true;
      this.saveIndex = data.index;
      this.questionIndex = data.section.index;
      this.showQuestionSelection = true;
      this.tempSection = {...data.section.section};
      // this.tempSection.data.id = Math.floor(Math.random() * 1000000);
    },
    addElement(data) {
      console.log('adding element');
      this.editingComponent = evalComponents.find((x)=>x.type===data.type);
      this.saveIndex = data.index;
      this.showEditModal = true;
      // this.addingQuestion = false;
    },
    addItem(component) {
      console.log('adding item');
      this.editingComponent = component;
      this.showQuestionSelection = false;
      // this.showEditModal = true;
      if (this.addingQuestion) {
        const section = this.$store.state.evaluation.evaluation.find((section)=>{
          return section.id === this.sectionSaveId;
        });
        const tempComponent = this.stampId(component);
        const tempData = {
          index: this.questionIndex,
          sectionId: this.sectionSaveId,
          component: tempComponent,
        };
        this.$store.dispatch('addQuestionToSection', tempData);
        // this.tempSection.data.questions.splice(this.questionIndex, 0, tempComponent);
      }
      console.log('down here');
    //   this.evaluationView.push(component);
    },
    stampId(component) {
      const cloned = {...component};
      cloned.id = Math.floor(Math.random() * 100000);
      return cloned;
    },
    saveComponent() {
      this.$refs.componentModal.editDone();
    },
    removeSection(index) {
      this.$store.dispatch('removeEvalSection', index);
      this.evaluationView.splice(index, 1);
    },
    removeSelection() {
      console.log('removing selection');
      this.$store.dispatch('clearEvalItem');
    },
    saveToView(component) {
      const payload = {};
      payload['saveIndex'] = this.saveIndex;
      payload['component'] = this.stampId(component);
      this.$store.dispatch('addSection', payload);
    },
  },
  async created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.removeSelection();
      }
    };

    document.addEventListener('keydown', escapeHandler);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler);
    });
    console.log('created eval');
    if (this.$route.params.evalId) {
      this.getEval(this.$route.params.evalId);
      await this.getEvaluationCategories();
    }
  },
  computed: {
    ...mapGetters(['lockedEval']),

    title() {
      return this.$store.state.evaluation.title;
    },
  },
  watch: {
    '$route.path'() {
      this.activeDocumentId = '';
      this.getEval(this.$route.params.evalId);
    },
  },
  data() {
    return {
      saving: false,
      activeDocumentId: '',
      selectEvalModal: false,
      sectionSaveId: 0,
      showQuestionSelection: false,
      showEditModal: false,
      evaluationView: [],
      editingComponent: {},
      tempSection: {},
      saveIndex: 0,
      questionIndex: 0,
    };
  },
};
</script>
<style lang="scss">
.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.componentSelect {
  cursor: pointer;

  &:hover {
    border-color: rgb(71, 71, 71);
    outline: 1px dashed;
    // margin: -2px;
    box-sizing: border-box;

    & > * {
      border: none;
      margin: auto;
    }
  }
}

.selectedComponent {
  box-sizing: border-box;
  outline: 2px solid blue;
  // margin: -2px;
}
</style>
