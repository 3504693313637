<template>
  <div>
    <div>
      <TrinityRingsSpinner
        style="margin: 0 auto;"
        :color="$themes.primary"
        v-if="loading"
      />
      <div v-else-if="newEvaluation">
        <div>
          <va-input
            label="Evaluation Name"
            v-model="newEvalTitle"
          />
        </div>
        <div>
          <va-button
            :disabled="newEvalTitle.length <= 0 || saving"
            @click="saveEval"
          >
            Save
          </va-button><va-button @click="newEvaluation = false">
            Cancel
          </va-button>
        </div>
      </div>
      <div v-else-if="renameEvaluation">
        <div>
          <va-input
            label="Evaluation Name"
            v-model="newEvalTitle"
          />
        </div>
        <div>
          <va-button
            :disabled="newEvalTitle.length <= 0 || saving"
            @click="updateEvalTitle"
          >
            Update
          </va-button><va-button @click="renameEvaluation = false">
            Cancel
          </va-button>
        </div>
      </div>
      <div v-else>
        <va-list
          fit
          class="mb-2"
        >
          <va-item
            clickable
            v-for="evaluation in evals"
            :key="evaluation.id"
          >
            <va-item-section side>
              <va-checkbox v-model="evaluation.selected" />
            </va-item-section>
            <va-item-section>
              <div class="row">
                {{ evaluation.data.title }} <va-icon
                  name="fa fa-lock"
                  class="pt-1 ml-3"
                  v-if="evaluation.data.locked"
                  color="gray"
                />
              </div>
            </va-item-section>
          </va-item>
        </va-list>


        <div>
          <va-button
            @click="openEval"
            :disabled="!hasSingleSelection"
          >
            Open
          </va-button>
          <va-button
            @click="renameEval"
            :disabled="!hasSingleSelection"
          >
            Rename
          </va-button>
          <va-button @click="newEvaluation = true">
            New
          </va-button>
          <va-button
            v-if="hasSelection && !hasDefaultSelected"
            @click="setNewDefault"
            :disabled="!hasSingleSelection"
            icon="fa fa-shield"
          >
          Set as default
          </va-button>
          <va-button
            v-if="hasSelection"
            @click="deleteEvals"
            icon="fa fa-trash"
            :large="true"
            :color="$store.state.app.config.palette.danger"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TrinityRingsSpinner} from 'epic-spinners';
import firebase from 'firebase';
export default {
  components: {
    TrinityRingsSpinner,
  },
  data() {
    return {
      loading: true,
      saving: false,
      newEvaluation: false,
      renameEvaluation: false,
      tempId: '',
      newEvalTitle: '',
      evals: [],
    };
  },
  created() {
    this.getEvals();
  },
  methods: {
    getEvals() {
      this.loading = true;
      this.evals = [];
      firebase.firestore().collection('evalTemplates').orderBy('title').get().then((snapshot)=>{
        snapshot.forEach((doc)=>{
          const evalData = {
            id: doc.id,
            data: doc.data(),
            selected: false,
          };
          this.evals.push(evalData);
        });
        this.loading = false;
      });
    },
    updateEvalTitle() {
      this.saving = true;
      firebase.firestore().collection('evalTemplates').doc(this.selectedEval.id).update({
        title: this.newEvalTitle,
      }).then(()=>{
        this.saving = false;
        this.newEvaluation = false;
        this.renameEvaluation = false;
        this.getEvals();
      });
    },
    saveEval() {
      this.saving = true;
      firebase.firestore().collection('evalTemplates').add({
        title: this.newEvalTitle,
        evaluation: [],
      }).then(()=>{
        this.saving = false;
        this.newEvaluation = false;
        this.getEvals();
      });
    },
    renameEval() {
      this.newEvalTitle = this.selectedEval.data.title;
      this.renameEvaluation = true;
    },
    openEval() {
      this.$router.push({name: 'eval', params: {evalId: this.selectedEval.id}});
    },
    setNewDefault(){
      const lockedEvals = this.selections.filter((obj)=>obj.data.locked);
      if (lockedEvals.length > 0) {
        this.$swal.fire({icon: 'error', title: 'Cannot Change locked Eval'});
      } else {
        this.$swal.fire({
          title: 'Are you sure want to change default eval?',
          text: 'When you change default eval, all the new evaluations will be based on this eval',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, change it!',
        }).then((result) => {
          if (result.isConfirmed) {
            //check if evalTemplates have other evals with default set to true
             this.$swal.fire({
                title: 'Saving Changes',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: async () => {
                  this.$swal.showLoading();
                  //fetch from firestore in collection users, the ones that has isAdmin = true
                  await firebase.firestore().collection('evalTemplates').where('default', '==', true).get().then(async (snapshot)=>{
                    if (snapshot.size > 0) {
                      snapshot.forEach(async (doc)=>{
                        await firebase.firestore().collection('evalTemplates').doc(doc.id).update({
                          default: false,
                        });
                      });
                    }
                   await firebase.firestore().collection('evalTemplates').doc(this.selectedEval.id).update({
                      default: true,
                    }).then(()=>{
                      this.$swal.fire(
                        'Changed!',
                        'Default eval has been changed.',
                        'success',
                      ).then(()=>{
                        this.$swal.close();
                        this.getEvals();
                      });
                    });
                  });
                },
            })
          }
        });
      }
    },
    deleteEvals() {
      const lockedEvals = this.selections.filter((obj)=>obj.data.locked);
      if (lockedEvals.length > 0) {
        this.$swal.fire({icon: 'error', title: 'Cannot Delete locked Eval'});
      } else {
        const message = this.selections.length === 1 ? `Are you sure want to delete '${this.selections[0].data.title}'?` : `Are you sure you want to delete ${this.selections.length} evals?`;
        this.$swal.fire({
          title: message,
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
          if (result.isConfirmed) {
            const deletes = [];
            this.selections.forEach((evaluation)=>{
              deletes.push(firebase.firestore().collection('evalTemplates').doc(evaluation.id).delete());
            });
            Promise.all(deletes).then(()=>{
              const deleteMessage = deletes.length === 1 ? ' has' : 's have';
              this.$swal.fire(
                  'Deleted!',
                  `Your evaluation${deleteMessage} been deleted.`,
                  'success',
              );
              this.getEvals();
            });
          }
        });
      }
    },
  },
  computed: {
    hasSingleSelection() {
      const selected = this.selections;
      let enabled = false;
      if (selected) {
        enabled = selected.length == 1;
      }
      return enabled;
    },
    hasMultipleSelected() {
      const selected = this.selections;
      let enabled = false;
      if (selected) {
        enabled = selected.length > 1;
      }
      return enabled;
    },
    selectedEval() {
      return this.evals.find((obj)=>obj.selected);
    },
    selections() {
      return this.evals.filter((obj)=>obj.selected);
    },
    hasSelection() {
      return this.selections.length > 0;
    },
    hasDefaultSelected(){
      //find in this.selections if any of the selected evals have default set to true
      return this.selections.find((obj)=>obj.data.default)
    }
  },
};
</script>

<style>

</style>
